import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { navigate } from 'gatsby';
import MainHeader from '../../components/main-header/main-header';
import PageHeader from '../../components/page-header/page-header';
import fieldMasks from '../../helpers/fieldMasks';
import { cancelEdit, saveEdit, isEditValid } from '../../helpers/editQuotation';
import Button from '../../components/button/button';
import iconReturn from '../../images/return.svg';
import Dropdown from '../../components/dropdown/dropdown';
import MaskedInput from 'react-input-mask';
import cn from 'classnames';
import { Checkbox } from '../../components/Argo-ui';
import { getAtividades, getUF, getCep } from '../../services/base-service';
import { DataContext } from '../../data/DataProvider';
import ExclamationIcon from '../../images/exclamation-circle.svg';
import './index.scss';
import MainFooter from '../../components/main-footer/main-footer';
import DefaultLayout from '../../components/layouts/default-layout';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import CheckSuccess from '../../images/check-success.svg';
import Toast from '../../components/toast/toast';
import { toast } from 'react-toastify';
import CustomToast from '../../components/toast/customToast';

const block = 'company-data';
const Page = ({ location }) => {
  const {
    quotation,
    setQuotation,
    activities,
    setActivities,
    originalQuotation,
    setModal,
    setOriginalQuotation,
    responseMotor,
  } = useContext(DataContext);
  const [uf, setUF] = useState();
  const [copyEnd, setCopyEnd] = useState(true);
  const [isEstado, setEstado] = useState(false);
  const [isEstadoCobranca, setEstadoCobranca] = useState(false);
  const [disableSave, setDisableSave] = useState(false);

  const [ignoreCepError, setIgnoreCepError] = useState(false);
  const [ignoreBillingCepError, setIgnoreBillingCepError] = useState(false);
  const [incompleteCep, setIncompleteCep] = useState(false);
  const [incompleteBillingCep, setIncompleteBillingCep] = useState(false);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const showToast = () => {
    if (quotation.dadosEmpresa.isCorretoraAkad) {
      toast.success(
        <CustomToast
          title="Você está cotando para a sua corretora"
          message="Ao final da cotação, você terá acesso a um preço diferenciado e exclusivo."
        />,
        {
          autoClose: 5000,
          icon: <img src={CheckSuccess} alt="Ícone de sucesso" />,
        }
      );
    }
  };

  useEffect(() => {
    if (!quotation || !Object.keys(quotation).length) navigate('/');
  }, [quotation]);

  useEffect(() => {
    showToast();
  }, []);

  const cancel = () => {
    cancelEdit(setQuotation, originalQuotation);
  };

  const onSubmit = async (values) => {
    const newData = {
      ...quotation,
      dadosEmpresa: {
        cnpj: quotation.dadosEmpresa.cnpj,
        razaoSocial: values.razaoSocial,
        nomeFantasia: values.nomeFantasia,
        email: values.email,
        isCorretoraAkad: quotation.dadosEmpresa.isCorretoraAkad,
        enderecoLocalRisco: {
          cep: values.cep,
          logradouro: values.logradouro,
          numero: values.numero,
          complemento: values.complemento,
          bairro: values.bairro,
          cidade: values.cidade,
          estado:
            quotation.dadosEmpresa.enderecoLocalRisco?.estado &&
            quotation.dadosEmpresa.enderecoLocalRisco.estado,
        },
        atividade: quotation.dadosEmpresa.atividade,
        telefonePrincipal: values.telefonePrincipal.replace(/_/g, ''),
        enderecoCobrancaMesmoLocalRisco:
          quotation.dadosEmpresa.enderecoCobrancaMesmoLocalRisco,
        enderecoCobranca: {
          cep: values.cepCobranca,
          logradouro: values.logradouroCobranca,
          numero: values.numeroCobranca,
          complemento: values.complementoCobranca,
          bairro: values.bairroCobranca,
          cidade: values.cidadeCobranca,
          estado:
            quotation.dadosEmpresa.enderecoCobranca?.estado &&
            quotation.dadosEmpresa.enderecoCobranca.estado,
        },
      },
      isProposal: false,
    };
    setQuotation({ ...newData });
    setDisableSave(true);
    if (quotation?.propostaId) {
      return saveEdit(
        newData,
        setModal,
        setDisableSave,
        setOriginalQuotation,
        responseMotor
      );
    }
    return navigate('/informacoes-do-seguro');
  };

  const intialValues = {
    razaoSocial:
      quotation?.dadosEmpresa?.razaoSocial &&
      quotation?.dadosEmpresa?.razaoSocial,
    nomeFantasia:
      quotation?.dadosEmpresa?.nomeFantasia &&
      quotation?.dadosEmpresa?.nomeFantasia,
    cep:
      quotation?.dadosEmpresa?.enderecoLocalRisco?.cep &&
      quotation?.dadosEmpresa?.enderecoLocalRisco?.cep,
    logradouro:
      quotation?.dadosEmpresa?.enderecoLocalRisco?.logradouro &&
      quotation?.dadosEmpresa?.enderecoLocalRisco?.logradouro,
    numero:
      quotation?.dadosEmpresa?.enderecoLocalRisco?.numero &&
      quotation?.dadosEmpresa?.enderecoLocalRisco?.numero,
    complemento:
      quotation?.dadosEmpresa?.enderecoLocalRisco?.complemento &&
      quotation?.dadosEmpresa?.enderecoLocalRisco?.complemento,
    bairro:
      quotation?.dadosEmpresa?.enderecoLocalRisco?.bairro &&
      quotation?.dadosEmpresa?.enderecoLocalRisco?.bairro,
    cidade:
      quotation?.dadosEmpresa?.enderecoLocalRisco?.cidade &&
      quotation?.dadosEmpresa?.enderecoLocalRisco?.cidade,
    estado:
      quotation?.dadosEmpresa?.enderecoLocalRisco?.estado &&
      quotation?.dadosEmpresa?.enderecoLocalRisco?.estado,
    email: quotation?.dadosEmpresa?.email && quotation?.dadosEmpresa?.email,
    telefonePrincipal:
      quotation?.dadosEmpresa?.telefonePrincipal &&
      quotation?.dadosEmpresa?.telefonePrincipal.replace(/_/g, ''),
    cepCobranca:
      quotation?.dadosEmpresa?.enderecoCobranca?.cep &&
      quotation?.dadosEmpresa?.enderecoCobranca?.cep,
    logradouroCobranca: quotation?.dadosEmpresa?.enderecoCobranca?.logradouro,
    numeroCobranca: quotation?.dadosEmpresa?.enderecoCobranca?.numero,
    complementoCobranca: quotation?.dadosEmpresa?.enderecoCobranca?.complemento,
    bairroCobranca: quotation?.dadosEmpresa?.enderecoCobranca?.bairro,
    cidadeCobranca: quotation?.dadosEmpresa?.enderecoCobranca?.cidade,
    estadoCobranca: quotation?.dadosEmpresa?.enderecoCobranca?.estado,
    atividade:
      quotation?.dadosEmpresa?.atividade && quotation?.dadosEmpresa.atividade,
    isCorretoraAkad:
      quotation?.dadosEmpresa.isCorretoraAkad &&
      quotation?.dadosEmpresa.isCorretoraAkad,
  };

  useEffect(() => {
    async function fetchData() {
      const atividades = await getAtividades();
      setActivities(atividades);
      const estados = await getUF();
      setUF(estados);
    }
    fetchData();
  }, [setActivities, setUF]);

  useEffect(() => {
    if (
      quotation &&
      quotation?.dadosEmpresa?.enderecoLocalRisco?.estado &&
      uf
    ) {
      let labelUF = uf.find(
        (item) =>
          item.label === quotation?.dadosEmpresa?.enderecoLocalRisco?.estado ||
          item.value === quotation?.dadosEmpresa?.enderecoLocalRisco?.estado
      );

      if (labelUF) {
        setEstado(labelUF.value);
      }
    }

    if (
      quotation &&
      quotation.dadosEmpresa?.enderecoCobranca?.cep &&
      quotation.dadosEmpresa?.enderecoCobranca?.estado &&
      uf
    ) {
      let labelUFCobranca = uf.find(
        (item) =>
          item.label === quotation.dadosEmpresa?.enderecoCobranca?.estado ||
          item.value === quotation.dadosEmpresa?.enderecoCobranca?.estado
      )?.value;
      setEstadoCobranca(labelUFCobranca);
    }

    if (quotation?.dadosEmpresa?.enderecoCobrancaMesmoLocalRisco) {
      setCopyEnd(true);
    } else {
      setCopyEnd(false);
    }
  }, [uf, quotation]);

  const atividadeChangeHandler = useCallback(
    (selectedItem) => {
      setQuotation({
        ...quotation,
        dadosEmpresa: {
          ...quotation.dadosEmpresa,
          atividade: selectedItem.value,
        },
        avaliacaoRisco: {
          ...quotation.avaliacaoRisco,
          valorRiscoMaximo: selectedItem.valorRiscoMaximo,
        },
      });
    },
    [quotation, setQuotation]
  );

  const estadoChangeHandler = useCallback(
    (selectedItem) => {
      setQuotation({
        ...quotation,
        dadosEmpresa: {
          ...quotation.dadosEmpresa,
          enderecoLocalRisco: {
            ...quotation.dadosEmpresa.enderecoLocalRisco,
            estado: selectedItem.value,
          },
        },
      });
    },
    [quotation, setQuotation]
  );

  const estadoCobrancaChangeHandler = useCallback(
    (event) => {
      setQuotation({
        ...quotation,
        dadosEmpresa: {
          ...quotation.dadosEmpresa,
          enderecoCobranca: {
            ...quotation.dadosEmpresa.enderecoCobranca,
            estado: event.value,
          },
        },
      });
    },
    [quotation, setQuotation]
  );

  const cepChangeHandler = useCallback(
    (cep, type) => {
      async function fetchDataCEP() {
        const searchCEP = await getCep(cep.replace('-', ''));
        if (searchCEP) {
          if (type === 'cobranca') {
            trackCustomEvent({
              category: 'Consulta de CEP de cobrança',
              action: '',
              label: cep.replace('-', ''),
            });
            setValue('logradouroCobranca', searchCEP.logradouro);
            setValue('bairroCobranca', searchCEP.bairro);
            setValue('cidadeCobranca', searchCEP.localidade);
            setValue('numeroCobranca', '');
            setValue('complementoCobranca', '');
            setQuotation({
              ...quotation,
              dadosEmpresa: {
                ...quotation.dadosEmpresa,
                enderecoCobranca: {
                  cep: searchCEP.cep,
                  logradouro: searchCEP.logradouro,
                  bairro: searchCEP.bairro,
                  cidade: searchCEP.localidade,
                  estado: searchCEP.uf,
                },
              },
            });
            if (
              (!searchCEP.bairro || !searchCEP.logradouro) &&
              !searchCEP.erro
            ) {
              setIncompleteBillingCep(true);
            } else {
              setIncompleteBillingCep(false);
            }

            if (searchCEP.erro) {
              setModal({
                values: {
                  headline: `O CEP ${cep} não pode ser verificado`,
                  message: `Não consta na nossa base de dados o Código de Endereçamento Postal informado.
                    Gostaria de prosseguir com o CEP informado e preencher o endereço manualmente?
                    Tal ação pode resultar em erro no momento do checkout.`,
                  secondaryButton: {
                    label: 'Cancelar',
                    handler: () => {
                      setValue('cepCobranca', '');
                    },
                  },
                  primaryButton: {
                    label: 'Confirmar',
                    handler: () => {
                      setIgnoreBillingCepError(true);
                    },
                  },
                },
              });
            }
            setIgnoreBillingCepError(false);
          } else {
            trackCustomEvent({
              category: 'Consulta de CEP',
              action: '',
              label: cep.replace('-', ''),
            });
            setValue('logradouro', searchCEP.logradouro);
            setValue('bairro', searchCEP.bairro);
            setValue('cidade', searchCEP.localidade);
            setValue('numero', '');
            setValue('complemento', '');
            setQuotation({
              ...quotation,
              dadosEmpresa: {
                ...quotation.dadosEmpresa,
                enderecoLocalRisco: {
                  cep: searchCEP.cep,
                  logradouro: searchCEP.logradouro,
                  bairro: searchCEP.bairro,
                  cidade: searchCEP.localidade,
                  estado: searchCEP.uf,
                },
              },
            });
            if (
              (!searchCEP.bairro || !searchCEP.logradouro) &&
              !searchCEP.erro
            ) {
              setIncompleteCep(true);
            } else {
              setIncompleteCep(false);
            }

            if (searchCEP.erro) {
              setModal({
                values: {
                  headline: `O CEP ${cep} não pode ser verificado`,
                  message: `Não consta na nossa base de dados o Código de Endereçamento Postal informado.
                    Gostaria de prosseguir com o CEP informado e preencher o endereço manualmente?
                    Tal ação pode resultar em erro no momento do checkout.`,
                  secondaryButton: {
                    label: 'Cancelar',
                    handler: () => {
                      setValue('cep', '');
                    },
                  },
                  primaryButton: {
                    label: 'Confirmar',
                    handler: () => {
                      setIgnoreCepError(true);
                    },
                  },
                },
              });
            }
            setIgnoreCepError(false);
          }
        }
      }
      fetchDataCEP();
    },
    [quotation, setQuotation, setValue]
  );

  return (
    <DefaultLayout currentPagePath={location.pathname}>
      <main className={block}>
        <MainHeader />
        <PageHeader
          title="Confirme os dados da empresa"
          backButtonHandler={() => navigate('/cnpj')}
          editMode={!!quotation?.propostaId}
          description={
            <span>
              Os dados são obtidos do cadastro da empresa na base do governo.
              <br />
              Você pode editá-los caso necessite.
            </span>
          }
          breadCrumbs={[
            {
              label: 'Início',
              path: '/',
            },
            {
              label: 'CNPJ',
              path: '/cnpj',
            },
            {
              label: 'Dados da empresa',
              path: '/dados-da-empresa',
            },
          ]}
          fixed
          hide={true}
        />

        <div className={`container--large ${block}--fixed`}>
          <Toast />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${block}__wrapper`}>
              <div className={`${block}__titleFormGroup`}>
                <span>Informações da empresa</span>
              </div>
              <div className={`${block}__formGroup`}>
                <div className={`${block}--row full-mobile`}>
                  <div className={`${block}--item-1`}>
                    <fieldset className="fieldset">
                      <label className="label" htmlFor="razaoSocial">
                        Razão Social
                      </label>
                      <input
                        defaultValue={intialValues.razaoSocial}
                        placeholder={'Informe a razão social'}
                        {...register('razaoSocial', {
                          validate: (value) => value.length > 1,
                          required: true,
                          minLength: 2,
                          maxLength: 100,
                        })}
                        className="input"
                      />
                      {errors.razaoSocial && (
                        <p className="error">
                          Verifique o preenchimento{' '}
                          <img
                            alt="Erro"
                            className="iconError"
                            src={ExclamationIcon}
                          />
                        </p>
                      )}
                    </fieldset>
                  </div>
                  <div className={`${block}--item-1`}>
                    <fieldset className="fieldset">
                      <label className="label" htmlFor="nomeFantasia">
                        Nome Fantasia
                      </label>
                      <input
                        defaultValue={intialValues.nomeFantasia}
                        placeholder={'Informe o nome fantasia'}
                        {...register('nomeFantasia', {
                          validate: (value) => value.length > 1,
                          required: true,
                          minLength: 2,
                          maxLength: 50,
                        })}
                        className="input"
                      />
                      {errors.nomeFantasia && (
                        <p className="error">
                          Verifique o preenchimento{' '}
                          <img
                            alt="Erro"
                            className="iconError"
                            src={ExclamationIcon}
                          />
                        </p>
                      )}
                    </fieldset>
                  </div>
                </div>
                <div className={`${block}--row full-mobile`}>
                  <div className={`${block}--item-1`}>
                    <fieldset className="fieldset">
                      <label className="label" htmlFor="email">
                        E-mail
                      </label>
                      <input
                        defaultValue={intialValues.email}
                        placeholder="Informe o e-mail"
                        {...register('email', {
                          validate: (value) => value.length > 4,
                          required: true,
                          minLength: 5,
                          maxLength: 50,
                          pattern:
                            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                        })}
                        className="input"
                      />
                      {errors.email && (
                        <p className="error">
                          Verifique o preenchimento{' '}
                          <img
                            alt="Erro"
                            className="iconError"
                            src={ExclamationIcon}
                          />
                        </p>
                      )}
                    </fieldset>
                  </div>
                  <div className={`${block}--item-1`}>
                    <fieldset className="fieldset">
                      <label className="label" htmlFor="telefonePrincipal">
                        Telefone de contato
                      </label>
                      <MaskedInput
                        defaultValue={intialValues.telefonePrincipal}
                        placeholder={'Informe o telefone de contato'}
                        {...register('telefonePrincipal', {
                          validate: (value) =>
                            value.match(/_/g) && value.match(/_/g).length <= 1,
                          required: true,
                          maxLength: 15,
                        })}
                        className="input"
                        mask={fieldMasks.tel}
                      />
                      {errors.telefonePrincipal && (
                        <p className="error">
                          Verifique o preenchimento{' '}
                          <img
                            alt="Erro"
                            className="iconError"
                            src={ExclamationIcon}
                          />
                        </p>
                      )}
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>

            <div className={`${block}__wrapper`}>
              <div className={`${block}__titleFormGroup`}>
                <span>Dados do local de risco</span>
              </div>
              <div className={`${block}__formGroup`}>
                <div className={`${block}--row`}>
                  <div className={`${block}--item-3`}>
                    <fieldset className="fieldset">
                      <label className="label" htmlFor="cep">
                        CEP
                      </label>
                      <img alt="return" src={iconReturn} className="iconCep" />
                      <MaskedInput
                        defaultValue={intialValues.cep}
                        placeholder={'Informe o CEP'}
                        /*onKeyUp={(val) => {
                          if (val.target.value.search('_') < 0) {
                            cepChangeHandler(val.target.value);
                          }
                        }}*/
                        onBlurCapture={(val) => {
                          if (val.target.value.search('_') < 0) {
                            cepChangeHandler(val.target.value);
                          }
                        }}
                        mask={fieldMasks.cep}
                        {...register('cep', {
                          validate: (value) => value.search('_') < 0,
                          required: true,
                          minLength: 9,
                          maxLength: 9,
                        })}
                        className="input"
                      />
                      {errors.cep && (
                        <p className="error">
                          Verifique o preenchimento{' '}
                          <img
                            alt="Erro"
                            className="iconError"
                            src={ExclamationIcon}
                          />
                        </p>
                      )}
                    </fieldset>
                  </div>
                  <div className={`${block}--item-4`}>
                    <fieldset className="fieldset">
                      <span className={`${block}__label`}>
                        Atividade comercial
                      </span>
                      <Dropdown
                        className={`${block}__dropdown`}
                        placeholder="Selecione"
                        items={activities ? activities : []}
                        changeHandler={atividadeChangeHandler}
                        selectedValue={intialValues.atividade}
                        {...register('atividade', {
                          validate: (value) => parseInt(value) > 0,
                        })}
                        size={'small'}
                      />
                      {errors.atividade && (
                        <p className="error">
                          Verifique o preenchimento{' '}
                          <img
                            alt="Erro"
                            className="iconError"
                            src={ExclamationIcon}
                          />
                        </p>
                      )}
                    </fieldset>
                  </div>
                </div>
                <div className={`${block}--row`}>
                  <div className={`${block}--item-1`}>
                    <fieldset
                      className="fieldset"
                      disabled={!ignoreCepError && !incompleteCep}
                    >
                      <label className="label" htmlFor="logradouro">
                        Endereço
                      </label>
                      <input
                        defaultValue={intialValues.logradouro}
                        placeholder="Informe o endereço"
                        {...register('logradouro', {
                          validate: (value) => value.length > 1,
                          required: true,
                          minLength: 2,
                          maxLength: 70,
                        })}
                        className="input"
                      />
                      {errors.logradouro && (
                        <p className="error">
                          Verifique o preenchimento{' '}
                          <img
                            alt="Erro"
                            className="iconError"
                            src={ExclamationIcon}
                          />
                        </p>
                      )}
                    </fieldset>
                  </div>
                  <div className={`${block}--item-2`}>
                    <fieldset className="fieldset">
                      <label className="label" htmlFor="numero">
                        Número
                      </label>
                      <input
                        defaultValue={intialValues.numero}
                        placeholder="Informe o número"
                        {...register('numero', {
                          minLength: 1,
                          maxLength: 18,
                        })}
                        className="input"
                      />
                      {errors.numero ? (
                        <p className="error">
                          Verifique o preenchimento{' '}
                          <img
                            alt="Erro"
                            className="iconError"
                            src={ExclamationIcon}
                          />
                        </p>
                      ) : (
                        <p className="helpText grey">Opcional</p>
                      )}
                    </fieldset>
                  </div>
                  <div className={`${block}--item-2`}>
                    <fieldset className="fieldset">
                      <label className="label" htmlFor="complemento">
                        Complemento
                      </label>
                      <input
                        defaultValue={intialValues.complemento}
                        placeholder="Informe o complemento"
                        {...register('complemento', {
                          maxLength: 100,
                        })}
                        className="input"
                      />
                      <p className="helpText grey">Opcional</p>
                    </fieldset>
                  </div>
                </div>
                <div className={`${block}--row`}>
                  <div className={`${block}--item-3`}>
                    <fieldset
                      className="fieldset"
                      disabled={!ignoreCepError && !incompleteCep}
                    >
                      <label className="label" htmlFor="bairro">
                        Bairro
                      </label>
                      <input
                        defaultValue={intialValues.bairro}
                        placeholder="Informe o bairro"
                        {...register('bairro', {
                          validate: (value) => value.length > 1,
                          required: true,
                          minLength: 2,
                          maxLength: 70,
                        })}
                        className="input"
                      />
                      {errors.bairro && (
                        <p className="error">
                          Verifique o preenchimento{' '}
                          <img
                            alt="Erro"
                            className="iconError"
                            src={ExclamationIcon}
                          />
                        </p>
                      )}
                    </fieldset>
                  </div>
                  <div className={`${block}--item-3`}>
                    <fieldset className="fieldset" disabled={!ignoreCepError}>
                      <label className="label" htmlFor="cidade">
                        Cidade
                      </label>
                      <input
                        defaultValue={intialValues.cidade}
                        placeholder="Informe o cidade"
                        {...register('cidade', {
                          validate: (value) => value.length > 1,
                          required: true,
                          minLength: 2,
                          maxLength: 70,
                        })}
                        className="input"
                      />
                      {errors.cidade && (
                        <p className="error">
                          Verifique o preenchimento{' '}
                          <img
                            alt="Erro"
                            className="iconError"
                            src={ExclamationIcon}
                          />
                        </p>
                      )}
                    </fieldset>
                  </div>
                  <div className={`${block}--item-3`}>
                    <fieldset className="fieldset" disabled={!ignoreCepError}>
                      <span className={`${block}__label`}>Estado</span>
                      <Dropdown
                        className={`${block}__dropdown`}
                        placeholder="Selecione"
                        items={uf}
                        changeHandler={estadoChangeHandler}
                        selectedValue={isEstado}
                        {...register('estado', {
                          validate: (value) => parseInt(value) > 0,
                        })}
                        size={'small'}
                        disabled={!ignoreCepError}
                      />
                      {errors.estado && (
                        <p className="error">
                          Verifique o preenchimento{' '}
                          <img
                            alt="Erro"
                            className="iconError"
                            src={ExclamationIcon}
                          />
                        </p>
                      )}
                    </fieldset>
                  </div>
                </div>
              </div>

              <div className={`${block}__wrapper`}>
                <div className={`${block}__titleFormGroup`}>
                  <span>Endereço de cobrança</span>
                </div>
                <div className={`${block}__formGroup`}>
                  <div className={`${block}--row`}>
                    <div className={`${block}--item-full`}>
                      <Checkbox
                        id="copy_end"
                        label="Usar mesmo endereço do local de risco"
                        labelSize={`${11 / 13}rem`}
                        onChange={(value) => {
                          if (value) {
                            trackCustomEvent({
                              category: 'Endereços iguais',
                              action: '',
                            });
                          } else {
                            trackCustomEvent({
                              category: 'Endereços diferentes',
                              action: '',
                            });
                          }
                          setCopyEnd(value);
                          setQuotation({
                            ...quotation,
                            dadosEmpresa: {
                              ...quotation.dadosEmpresa,
                              enderecoCobrancaMesmoLocalRisco: value,
                            },
                          });
                        }}
                        checked={copyEnd}
                        large
                      />
                    </div>
                  </div>
                  <div
                    className={cn(`${block}--wrapper-cobranca`, {
                      [`${block}--show`]: !copyEnd,
                    })}
                  >
                    <div className={`${block}--row`}>
                      <div className={`${block}--item-3`}>
                        <fieldset className="fieldset" disabled={copyEnd}>
                          <label className="label" htmlFor="cepCobranca">
                            CEP
                          </label>
                          <img
                            alt="return"
                            src={iconReturn}
                            className="iconCep"
                          />
                          <MaskedInput
                            defaultValue={intialValues.cepCobranca}
                            placeholder={'Informe o CEP'}
                            className="input"
                            /*onKeyUp={(val) => {
                              if (val.target.value.search('_') < 0) {
                                cepChangeHandler(val.target.value, 'cobranca');
                              }
                            }}*/
                            onBlurCapture={(val) => {
                              if (val.target.value.search('_') < 0) {
                                cepChangeHandler(val.target.value, 'cobranca');
                              }
                            }}
                            mask={fieldMasks.cep}
                            {...register('cepCobranca', {
                              validate: (value) => value.search('_') < 0,
                              minLength: 9,
                              required: !copyEnd,
                            })}
                          />
                          {errors.cepCobranca && (
                            <p className="error">
                              Verifique o preenchimento{' '}
                              <img
                                alt="Erro"
                                className="iconError"
                                src={ExclamationIcon}
                              />
                            </p>
                          )}
                        </fieldset>
                      </div>
                    </div>
                    <div className={`${block}--row`}>
                      <div className={`${block}--item-1`}>
                        <fieldset
                          className="fieldset"
                          disabled={
                            !ignoreBillingCepError && !incompleteBillingCep
                          }
                        >
                          <label className="label" htmlFor="logradouroCobranca">
                            Endereço
                          </label>
                          <input
                            defaultValue={intialValues.logradouroCobranca}
                            {...register('logradouroCobranca', {
                              minLength: 2,
                              maxLength: 70,
                              required: !copyEnd,
                            })}
                            placeholder="Informe o endereço"
                            className="input"
                          />
                          {errors.logradouroCobranca && (
                            <p className="error">
                              Verifique o preenchimento{' '}
                              <img
                                alt="Erro"
                                className="iconError"
                                src={ExclamationIcon}
                              />
                            </p>
                          )}
                        </fieldset>
                      </div>
                      <div className={`${block}--item-2`}>
                        <fieldset className="fieldset">
                          <label className="label" htmlFor="numeroCobranca">
                            Número
                          </label>
                          <input
                            defaultValue={intialValues.numero}
                            placeholder="Informe o número"
                            className="input"
                            onKeyUp={(val) =>
                              val.target.value.length > 1
                                ? setQuotation({
                                    ...quotation,
                                    dadosEmpresa: {
                                      ...quotation.dadosEmpresa,
                                      enderecoCobranca: {
                                        ...quotation.dadosEmpresa
                                          .enderecoCobranca,
                                        numero: val.target.value,
                                      },
                                    },
                                  })
                                : setQuotation({
                                    ...quotation,
                                    dadosEmpresa: {
                                      ...quotation.dadosEmpresa,
                                      enderecoCobranca: {
                                        ...quotation.dadosEmpresa
                                          .enderecoCobranca,
                                        numero: '',
                                      },
                                    },
                                  })
                            }
                            {...register('numeroCobranca', {
                              maxLength: 18,
                            })}
                          />
                          {errors.numeroCobranca ? (
                            <p className="error">Verifique o preenchimento</p>
                          ) : (
                            <p className="helpText grey">Opcional</p>
                          )}
                        </fieldset>
                      </div>
                      <div className={`${block}--item-2`}>
                        <fieldset className="fieldset">
                          <label
                            className="label"
                            htmlFor="complementoCobranca"
                          >
                            Complemento
                          </label>
                          <input
                            defaultValue={intialValues.complemento}
                            placeholder="Informe o complemento"
                            {...register('complementoCobranca', {
                              maxLength: 100,
                            })}
                            className="input"
                          />
                          {errors.complementoCobranca ? (
                            <p className="error">Excedeu o limite permitido</p>
                          ) : (
                            <p className="helpText grey">Opcional</p>
                          )}
                        </fieldset>
                      </div>
                    </div>
                    <div className={`${block}--row`}>
                      <div className={`${block}--item-3`}>
                        <fieldset
                          className="fieldset"
                          disabled={
                            !ignoreBillingCepError && !incompleteBillingCep
                          }
                        >
                          <label className="label" htmlFor="bairroCobranca">
                            Bairro
                          </label>
                          <input
                            defaultValue={intialValues.bairro}
                            placeholder="Informe o bairro"
                            className="input"
                            onKeyUp={(val) =>
                              val.target.value.length > 1
                                ? setQuotation({
                                    ...quotation,
                                    dadosEmpresa: {
                                      ...quotation.dadosEmpresa,
                                      enderecoCobranca: {
                                        ...quotation.dadosEmpresa
                                          .enderecoCobranca,
                                        bairro: val.target.value,
                                      },
                                    },
                                  })
                                : setQuotation({
                                    ...quotation,
                                    dadosEmpresa: {
                                      ...quotation.dadosEmpresa,
                                      enderecoCobranca: {
                                        ...quotation.dadosEmpresa
                                          .enderecoCobranca,
                                        bairro: '',
                                      },
                                    },
                                  })
                            }
                            {...register('bairroCobranca', {
                              minLength: 2,
                              maxLength: 70,
                              required: !copyEnd,
                            })}
                          />
                          {errors.bairroCobranca && (
                            <p className="error">
                              Verifique o preenchimento{' '}
                              <img
                                alt="Erro"
                                className="iconError"
                                src={ExclamationIcon}
                              />
                            </p>
                          )}
                        </fieldset>
                      </div>
                      <div className={`${block}--item-3`}>
                        <fieldset
                          className="fieldset"
                          disabled={!ignoreBillingCepError}
                        >
                          <label className="label" htmlFor="cidadeCobranca">
                            Cidade
                          </label>
                          <input
                            defaultValue={intialValues.cidade}
                            placeholder="Informe o cidade"
                            className="input"
                            onKeyUp={(val) =>
                              val.target.value.length > 1
                                ? setQuotation({
                                    ...quotation,
                                    dadosEmpresa: {
                                      ...quotation.dadosEmpresa,
                                      enderecoCobranca: {
                                        ...quotation.dadosEmpresa
                                          .enderecoCobranca,
                                        cidade: val.target.value,
                                      },
                                    },
                                  })
                                : setQuotation({
                                    ...quotation,
                                    dadosEmpresa: {
                                      ...quotation.dadosEmpresa,
                                      enderecoCobranca: {
                                        ...quotation.dadosEmpresa
                                          .enderecoCobranca,
                                        cidade: '',
                                      },
                                    },
                                  })
                            }
                            {...register('cidadeCobranca', {
                              minLength: 2,
                              maxLength: 70,
                              required: !copyEnd,
                            })}
                          />
                          {errors.cidadeCobranca && (
                            <p className="error">
                              Verifique o preenchimento{' '}
                              <img
                                alt="Erro"
                                className="iconError"
                                src={ExclamationIcon}
                              />
                            </p>
                          )}
                        </fieldset>
                      </div>
                      <div className={`${block}--item-3`}>
                        <fieldset
                          className="fieldset"
                          disabled={!ignoreBillingCepError}
                        >
                          <span className={`${block}__label`}>Estado</span>
                          <Dropdown
                            className={`${block}__dropdown`}
                            placeholder="Selecione"
                            items={uf}
                            changeHandler={estadoCobrancaChangeHandler}
                            selectedValue={isEstadoCobranca}
                            required={!copyEnd}
                            {...register('estadoCobranca', {
                              required: !copyEnd,
                            })}
                            size={'small'}
                            disabled={!ignoreBillingCepError}
                          />
                          {errors.estadoCobranca && (
                            <p className="error">
                              Verifique o preenchimento{' '}
                              <img
                                alt="Erro"
                                className="iconError"
                                src={ExclamationIcon}
                              />
                            </p>
                          )}
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isEditValid(quotation) && (
                <div className="action_buttons">
                  <Button
                    className="button_cta"
                    type="button"
                    theme={'secondary'}
                    onClick={cancel}
                    disabled={disableSave}
                  >
                    Cancelar edição
                  </Button>
                  <Button
                    className={`button_cta button--margin-left`}
                    type="submit"
                    theme={'tertiary'}
                    disabled={!errors || disableSave}
                  >
                    Salvar edição
                  </Button>
                </div>
              )}
              {!quotation?.propostaId && (
                <Button className="button_cta" type="submit" disabled={!errors}>
                  Avançar
                </Button>
              )}
            </div>
          </form>
        </div>
        <MainFooter />
      </main>
    </DefaultLayout>
  );
};

export default Page;
