import React from 'react';
import './customToast.scss';

const CustomToast = ({ title, message }) => {
  return (
    <div className='custom-toast'>
      <strong className='title'>{title}</strong>
      <div className='message'>{message}</div>
    </div>
  );
};

export default CustomToast;